(function()
{
 	var app = angular.module('accessibility', []);

 	app.accessibility_reset = function()
	{
		// Reset font size to 1em.
		jQuery("body").css(
		{
	        "font-size": "1em"
	    });

		// Reset font family for anything with font family set.
	    jQuery("*").filter(function()
        {
        	return (jQuery(this).css('font-family') != "");
        }).css(
		{
			"font-family" : ""
		});

		// Reset colour for anything with colour set.
	    jQuery("*").filter(function()
        {
        	return (jQuery(this).css('color') != "");
        }).css(
		{
			"color" : ""
		});

		// Reset background colour for anything with background colour set.
	    jQuery("*").filter(function()
        {
        	return (jQuery(this).css('background-color') != "");
        }).css(
		{
			"background-color" : ""
		});

		jQuery("#accessibility-font-size").val("1");
		jQuery("#accessibility-font-colour").val("");
		jQuery("#accessibility-background-colour").val("");
		jQuery("#accessibility-font-family").val("");

	    setCookie("accessibility-font-size", "1", 365);
		setCookie("accessibility-font-colour", "", 365);
		setCookie("accessibility-background-colour", "", 365);
		setCookie("accessibility-font-family", "", 365);
	};

	app.accessibility_set = function()
	{
		let new_font_size 	= jQuery("#accessibility-font-size").val();
		let new_colour 		= jQuery("#accessibility-font-colour").val();
		let new_background 	= jQuery("#accessibility-background-colour").val();
		let new_font 		= jQuery("#accessibility-font-family").val();

		if(new_colour != "" && new_background != "" && new_colour == new_background)
		{
			error("Conflicting Colours", "You can't set the text colour and background colour to the same value or you won't be able to see anything!", true, null);
			new_colour = "";
			new_background = "";
		}

		// Apply selected font size to the body.  Everything else will scale from there.
		jQuery("body").css(
		{
	        "font-size": new_font_size + "em"
	    });

		// Reset font family for anything with font family set.
	    jQuery("*").filter(function()
        {
        	return (jQuery(this).css('font-family') != "");
        }).css(
		{
			"font-family" : ""
		});

		// Reset colour for anything with colour set.
	    jQuery("*").filter(function()
        {
        	return (jQuery(this).css('color') != "");
        }).css(
		{
			"color" : ""
		});

		// Reset background colour for anything with background colour set.
	    jQuery("*").filter(function()
        {
        	return (jQuery(this).css('background-color') != "");
        }).css(
		{
			"background-color" : ""
		});

	    // Apply selected font to everything with font family set.
	    if(new_font != "")
	    {
		    jQuery("*").filter(function()
            {
            	return (jQuery(this).css('font-family') != "");
            }).css(
			{
		        "font-family": new_font
		    });
		}

	    // Apply selected colour to everything with background colour set.
		if(new_colour != "")
	    {
			jQuery("*").filter(function()
            {
            	return (jQuery(this).css('color') != "");
            }).css(
			{
		        "color": new_colour
		    });
		}

	    // Apply selected background colour to everything which has a background colour set.
		if(new_background != "")
	    {
			jQuery("*").filter(function()
            {
            	return (jQuery(this).css('background-color') != "");
            }).css(
			{
		        "background-color": new_background
		    });
		}

		// Fix Font Awesome.
		jQuery("body .fa").css(
		{
	        "font-family": "FontAwesome"
	    });

	    setCookie("accessibility-font-size", new_font_size, 365);
		setCookie("accessibility-font-colour", new_colour, 365);
		setCookie("accessibility-background-colour", new_background, 365);
		setCookie("accessibility-font-family", new_font, 365);
	};

	app.googleTranslateElementInit = function()
	{
		new google.translate.TranslateElement
		(
			{
				pageLanguage: 'en', layout: google.translate.TranslateElement.InlineLayout.SIMPLE
			},
			'google_translate_element'
		);
	};

 	app.run(["$rootScope", function($rootScope)
 	{
 		jQuery(".accessibility-button").click(function(e)
		{
			e.preventDefault();

			jQuery("#accessibility-dialog").appendTo("#overlay").removeClass("hidden");
			jQuery("#overlay").css(
            {
            	left: 0,
            	right: "auto"
            }).animate(
			{
				height: "toggle",
				width: "toggle"
			}, 300);
		});

 		$rootScope.closeAccessibility = function()
 		{
 			jQuery("#overlay").animate(
			{
				height: "toggle",
				width: "toggle"
			}, 300, function()
			{
				jQuery("#accessibility-dialog").appendTo("body").addClass("hidden");
			});
 		};

		$rootScope.resetAccessibility = function()
		{
			app.accessibility_reset();
		};

		var font_size 	= (getCookie("accessibility-font-size") != "" ? getCookie("accessibility-font-size") : "1");
		var colour 		= getCookie("accessibility-font-colour");
		var background 	= getCookie("accessibility-background-colour");
		var font 		= getCookie("accessibility-font-family");

		jQuery("#accessibility-font-size").val(font_size);
		jQuery("#accessibility-font-colour").val(colour);
		jQuery("#accessibility-background-colour").val(background);
		jQuery("#accessibility-font-family").val(font);

		jQuery(".accessibility-control").change(function()
		{
			app.accessibility_set();
			jQuery("#accessibility-font-colour-presets").val("");
		});

		jQuery("#accessibility-font-colour-presets").change(function()
		{
			var parts = jQuery(this).val().split("|");

			jQuery("#accessibility-font-colour").val(parts[0]);
			jQuery("#accessibility-background-colour").val(parts[1]);
			app.accessibility_set();
		});

	}]);


})();
